import React from "react";
import { Link, useParams } from "react-router-dom";

// Components
import { AudioButton } from "../../components/AudioButton";

// Helpers
import { STORY } from "../../constants/Story";

// Assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Next } from "../../assets/icons/Forward.svg";
import { ReactComponent as Back } from "../../assets/icons/Back.svg";

// Styles
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  > div {
    padding: 2vh 2vh 0 2vh;
    > a {
      display: block;
      width: 80px;
      float: left;
      svg {
        transform: scale(0.9);
        &:hover {
          transform: scale(1);
        }
      }
    }
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 50px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;
const StoryContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  > div {
    width: 100%;
    max-height: 80vh;
    margin: auto 0;
    > img {
      max-height: 80vh;
      max-width: 100%;
    }
  }
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 10vh;
  width: 100%;
  > div {
    width: 30vh;
    margin: 0 auto;
    padding-bottom: 2vh;
  }
`;
const ButtonContainer = styled.div`
  float: left;
  width: 8vh;
  height: 8vh;
  padding: 0 1vh;
`;

const Story = () => {
  const [chapter, setChapter] = React.useState(0);
  const { story } = useParams();
  const storypart = STORY.find(stories => stories.name === story);
  const chapters = storypart ? storypart.chapters : STORY[0].chapters;

  // TODO OnEnded Knöpfe klar hervorheben
  // TODO Ende Teil 1 weiter zu Teil 2
  // TODO Nach letztem Teil? Zu den Liedern?

  const next = () => setChapter(chapter + 1);
  const previous = () => setChapter(chapter - 1);

  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>
        <StoryContainer>
          <div>
            <img src={require(`../../assets/Geschichte/${chapters[chapter].image}.svg`)} alt={chapters[chapter].image} />
          </div>
        </StoryContainer>

        <Footer>
          <div>
            <ButtonContainer> 
              <Back onClick={() => previous()} style={{display: chapter === 0 ? "none" : "initial" }} />
            </ButtonContainer>
            <ButtonContainer>
              <AudioButton chapter={chapter} story={story} />
            </ButtonContainer>
            <ButtonContainer>
              <Next onClick={() => next()} style={{display: chapter >= chapters.length - 1 ? "none" : "initial" }} />
            </ButtonContainer>
          </div>
        </Footer>

      </Main>
	  </>
  );
};

export default Story;
