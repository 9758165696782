import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useSound from 'use-sound';

// interfaces
import { IDisplayImage } from "../../interfaces"; 

// helpers
import { shuffleArray } from "../../features/helpers";
import { GAME_LENGTH, GAME_STATUS, GAME_COLORS } from "../../constants";
import { CORRECT_ANSWERS, INCORRECT_ANSWERS } from "../../constants/Data";
import { WORDS } from "../../constants/Words";
import { SENTENCES } from "../../constants/Sentences";

// Components
import { SentencesAudio } from "../../components/SentencesAudio";
import StatusSmiley from "../../components/StatusSmiley";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Replay } from "../../assets/icons/Replay.svg";
import feedback from "../../assets/Uebungen/feedback.mp3";

// Styles
import { Mobile, TabletAndDesktop } from "../../app/mediaQueries";
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const SentenceContainer = styled.div`
  display: flex;
  min-height: 88vh;
  padding-top: 2vh;
  padding-bottom: 8vh;
  > div {
    width: 100%;
    margin: auto 0;
    > div.floating {
      float: left;
      width: 33%;
    }
  }
`;
const SmileyContainer = styled.div`
  width: 10%;
  margin: 0 auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
`;
const SentenceContainerMobile = styled.div`
  height: 90vh;
  padding-top: 2vh;
  padding-bottom: 8vh;
  > div {
    height: 100%;
  }
`;
const ImageContainer = styled.div`
  border: 1vh solid white;
  >img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
`;
const ImageContainerMobile = styled.div`
  height: 25%;
  display: flex;
  >img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    border: 1vh solid white;
  }
`;
const SmileyContainerMobile = styled.div`
  width: 16%;
  margin: 0 auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 10vh;
  width: 100%;
`;
const ButtonContainer = styled.div`
  margin: 0 auto;
  width: 8vh;
  height: 8vh;
  padding: 0 2vh;
`;
const ReplayContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 15vh;
  height: 15vh;
  padding: 0 2vh;
`;

const SentencesGame = () => {
  const { topic } = useParams();
  const [index, setIndex] = useState(0);
  const [ignoreClick, setIgnoreClick] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const [status, setStatus] = useState(GAME_STATUS.NEUTRAL);
  const [displayImages, setDisplayImages] = useState(new Array<IDisplayImage>());
  const [selection, setSelection] = useState(new Array<IDisplayImage>());
  const data = SENTENCES.find(sentences => sentences.name === topic);
  const sentencesData = data ? data.sentences : SENTENCES[0].sentences
  const [sentences, setSentences] = useState(shuffleArray(sentencesData).slice(0, GAME_LENGTH.SENTENCE));
  const wordsData = WORDS.find(words => words.name === topic);
  const words = wordsData ? wordsData.words : WORDS[0].words

  const images = words.map(i =>  i.image);

  const [play] = useSound(feedback, {
    sprite: {
      correct1: [650, 2600],
      correct2: [5750, 1000],
      correct3: [7500, 1800],
      correct4: [9200, 4200],
      correct5: [14800, 2500],
      correct6: [19800, 3000],
      correct7: [22500, 1500],
      correct8: [24800, 2000],
      correct9: [27000, 2000],
      correct10: [30000, 3000],
      correct11: [36000, 2000],
      correct12: [39800, 3000],
      correct13: [43200, 2300],
      correct14: [47500, 3500],
      incorrect1: [53000, 2500],
      incorrect2: [56400, 2000],
      incorrect3: [60000, 2000],
      incorrect4: [62500, 2800],
      incorrect5: [66000, 1500],
      incorrect6: [68000, 2000],
      incorrect7: [70500, 1500],
      incorrect8: [72000, 2000],
      incorrect9: [75000, 3000],
      incorrect10: [79000, 1500],
      incorrect11: [81500, 2000],
    }
  });

  const replay = () => {
    setShowRepeat(false);
    setIndex(0);
    setIgnoreClick(false);
    setSelection(new Array<IDisplayImage>());
    setSentences(shuffleArray(data).slice(0, GAME_LENGTH.SENTENCE));
  }

  const checkSelection = (displayImage: IDisplayImage ) => {
    if(ignoreClick) {
      return;
    }
    if(selection.findIndex(e => e.image === displayImage.image) >= 0) {
      return;
    }

    if(displayImage.isSolution)
    {
      displayImage.backgroundColor = GAME_COLORS.SUCCESS;
      selection.push(displayImage);
      if(selection.length >=2) {
        play({ id: CORRECT_ANSWERS[Math.floor(Math.random() * CORRECT_ANSWERS.length)] });
        setIgnoreClick(true);
        setStatus(GAME_STATUS.CORRECT);
        setTimeout(next, 2000);
      } else {
        setStatus(GAME_STATUS.WAITING);
      }
    }
    else
    {
      play({ id: INCORRECT_ANSWERS[Math.floor(Math.random() * INCORRECT_ANSWERS.length)] });
      setIgnoreClick(true);
      displayImages.forEach(image => image.backgroundColor = image.isSolution ? GAME_COLORS.SUCCESS : GAME_COLORS.ERROR );
      setStatus(GAME_STATUS.WRONG);
      setTimeout(next, 2000);
    }
  }

  useEffect(() => {
    getImages();
  }, [index]);

  const getImages = () => {
    setSelection(new Array<IDisplayImage>());
    let tempImages = shuffleArray(images);
    let tempDisplayImages = new Array<IDisplayImage>();
    tempDisplayImages.push({ image: sentences[index].image1, isSolution: true, backgroundColor: GAME_COLORS.NEUTRAL});
    tempDisplayImages.push({ image: sentences[index].image2, isSolution: true, backgroundColor: GAME_COLORS.NEUTRAL });

    for(let i = 0; i <= tempImages.length; i++)
    {
      if (tempImages[i] !== sentences[index].image)
        tempDisplayImages.push({ image: tempImages[i], isSolution: false, backgroundColor: GAME_COLORS.NEUTRAL });
      if (tempDisplayImages.length >= 3)
        break;
    }
    setDisplayImages(shuffleArray(tempDisplayImages));
  }

  const next = () => {
    setDisplayImages(new Array<IDisplayImage>());
    setStatus(GAME_STATUS.NEUTRAL);

    if (index >= sentences.length - 1) {
      setShowRepeat(true);
    } else {
      setIndex(index + 1);
      setIgnoreClick(false);
    }
  }

  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/uebungen/thema/${topic}`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>

        <TabletAndDesktop>
          <SentenceContainer>
            {
              displayImages.length >= 3 
                ?
                  <div>
                    <SmileyContainer>
                      <StatusSmiley status={status} />
                    </SmileyContainer>

                    <div className="floating">
                      <ImageContainer style={{ borderColor: displayImages[0].backgroundColor }}>
                        <img src={require(`../../assets/Bilder/${displayImages[0].image}.svg`)} 
                            onClick={() => checkSelection(displayImages[0])} alt={displayImages[0].image} />
                      </ImageContainer>
                    </div>

                    <div className="floating">
                      <ImageContainer style={{ borderColor: displayImages[1].backgroundColor }}>
                        <img src={require(`../../assets/Bilder/${displayImages[1].image}.svg`)} 
                            onClick={() => checkSelection(displayImages[1])} alt={displayImages[1].image}  />
                      </ImageContainer>
                    </div>

                    <div className="floating">
                      <ImageContainer style={{ borderColor: displayImages[2].backgroundColor }} >
                        <img src={require(`../../assets/Bilder/${displayImages[2].image}.svg`)} 
                            onClick={() => checkSelection(displayImages[2])} alt={displayImages[2].image} />
                      </ImageContainer>
                    </div>
                  </div>
                :
                  <ReplayContainer>
                    <Replay onClick={replay} />
                  </ReplayContainer>
            }
          </SentenceContainer>
        </TabletAndDesktop>

        <Mobile>
          <SentenceContainerMobile>
              {
                displayImages.length >= 3 
                  ?
                    <div>
                      <SmileyContainerMobile>
                        <StatusSmiley status={status} />
                      </SmileyContainerMobile>
                      <ImageContainerMobile>
                        <img src={require(`../../assets/Bilder/${displayImages[0].image}.svg`)} 
                            onClick={() => checkSelection(displayImages[0])} alt={displayImages[0].image}
                            style={{ borderColor: displayImages[0].backgroundColor }} />
                        </ImageContainerMobile>
                        <ImageContainerMobile>
                          <img src={require(`../../assets/Bilder/${displayImages[1].image}.svg`)} 
                              onClick={() => checkSelection(displayImages[1])} alt={displayImages[1].image}
                              style={{ borderColor: displayImages[1].backgroundColor }} />
                        </ImageContainerMobile>
                        <ImageContainerMobile>
                          <img src={require(`../../assets/Bilder/${displayImages[2].image}.svg`)} 
                              onClick={() => checkSelection(displayImages[2])} alt={displayImages[2].image}
                              style={{ borderColor: displayImages[2].backgroundColor }} />
                        </ImageContainerMobile>
                    </div>
                  :
                    <ReplayContainer>
                      <Replay onClick={replay} />
                    </ReplayContainer>
              }
          </SentenceContainerMobile>
        </Mobile>

        <Footer>
          {
            !showRepeat
            ?
              <ButtonContainer>
                <SentencesAudio sentence={sentences[index].spriteId} />
              </ButtonContainer>
            :
              <></>
          }
          
        </Footer>
      </Main>
	  </>
  );
};

export default SentencesGame;
