import { ITopic, IGroups } from "../interfaces";

export const GROUPS:Array<IGroups> = [
  {
    topic: "zuhause",
    groups: [
      { name: "haus", image: "133_Haus", coloredImage: "133_Haus" },
      { name: "familie", image: "140_Familie", coloredImage: "140_Familie" },
      { name: "zaehneputzen", image: "144_Zähne_putzen", coloredImage: "144_Zähne_putzen" },
      { name: "sofa", image: "118_Sofa", coloredImage: "118_Sofa" },
      { name: "buntstifte", image: "149_Die Buntstifte", coloredImage: "149_Die Buntstifte" },
      { name: "zeichnen", image: "156_zeichnen", coloredImage: "156_zeichnen" },
    ]
  },
  {
    topic: "koerper",
    groups: [
      { name: "auge", image: "212_Das Auge", coloredImage: "212_Das Auge" },
      { name: "mund", image: "216_Der Mund", coloredImage: "216_Der Mund" },
      { name: "seife", image: "224_Die Seife", coloredImage: "224_Die Seife" },
      { name: "gross", image: "801_gross", coloredImage: "801_gross" },
      { name: "geben", image: "844_geben", coloredImage: "844_geben" },
      { name: "zahlen", image: "1_Finger", coloredImage: "1_Finger" },
    ]
  },
  {
    topic: "kleider",
    groups: [
      { name: "hose", image: "301_Die Hose", coloredImage: "301_Die Hose" },
      { name: "stiefel", image: "330_Die Stiefel", coloredImage: "330_Die Stiefel" },
      { name: "handschuhe", image: "316_Die Handschuhe", coloredImage: "316_Die Handschuhe" },
      { name: "bunt", image: "341_bunt", coloredImage: "341_bunt" },
    ]
  },
  {
    topic: "essen",
    groups: [
      { name: "apfel", image: "401_Der Apfel", coloredImage: "401_Der Apfel" },
      { name: "joghurt", image: "414_Das Joghurt", coloredImage: "414_Das Joghurt" },
      { name: "glace", image: "424_Das Glace", coloredImage: "424_Das Glace" },
      { name: "trinken", image: "435_trinken", coloredImage: "435_trinken" },
      { name: "viel", image: "813_viel", coloredImage: "813_viel" },
    ]
  },
  {
    topic: "natur",
    groups: [
      { name: "blume", image: "502_Die Blume", coloredImage: "502_Die Blume" },
      { name: "hase", image: "508_Der Hase", coloredImage: "508_Der Hase" },
      { name: "frosch", image: "525_Der Frosch", coloredImage: "525_Der Frosch" },
      { name: "oben", image: "827_oben", coloredImage: "827_oben" },
    ]
  },
  {
    topic: "bauernhof",
    groups: [
      { name: "schwein", image: "607_Das Schwein", coloredImage: "607_Das Schwein" },
      { name: "traktor", image: "616_Der Traktor", coloredImage: "616_Der Traktor" },
      { name: "katze", image: "609_Die Katze", coloredImage: "609_Die Katze" },
      { name: "kran", image: "651_Der Kran", coloredImage: "651_Der Kran" },
    ]
  },
  {
    topic: "jahr",
    groups: [
      { name: "sonne", image: "705_Die Sonne", coloredImage: "705_Die Sonne" },
      { name: "geschenk", image: "719_Das Geschenk", coloredImage: "719_Das Geschenk" },
      { name: "stern", image: "728_Der Stern", coloredImage: "728_Der Stern" },
      { name: "ball", image: "730_Der Ball", coloredImage: "730_Der Ball" },
    ]
  }
];

export const WORDS:Array<ITopic> = [
  {
    name: "zuhause",
    words: [
      { spriteId: "101", image: "101_Mutter_Frau", word: "Die Mutter / Die Frau", group: "familie" },
      { spriteId: "102", image: "102_Vater_der_Mann", word: "Der Vater / Der Mann", group: "familie" },
      { spriteId: "103", image: "103_Bruder", word: "Der Bruder", group: "familie" },
      { spriteId: "104", image: "104_Schwester", word: "Die Schwester", group: "familie" },
      { spriteId: "105", image: "105_Molnar", word: "Ich, Molnar", group: "familie" },
      { spriteId: "106", image: "106_Grossmutter", word: "Die Grossmutter", group: "familie" },
      { spriteId: "107", image: "107_Grossvater", word: "Der Grossvater", group: "familie" },
      { spriteId: "108", image: "108_Tür", word: "Die Tür", group: "haus" },
      { spriteId: "109", image: "109_Fenster", word: "Das Fenster", group: "haus" },
      { spriteId: "110", image: "110_Küche", word: "Die Küche", group: "haus" },
      { spriteId: "111", image: "111_Badezimmer", word: "Das Badezimmer", group: "zaehneputzen" },
      { spriteId: "112", image: "112_Schlafzimmer", word: "Das Schlafzimmer", group: "haus" },
      { spriteId: "113", image: "113_Wohnzimmer", word: "Das Wohnzimmer", group: "haus" },
      { spriteId: "114", image: "114_Dach", word: "Das Dach", group: "haus" },
      { spriteId: "115", image: "115_Garten", word: "Der Garten", group: "haus" },
      { spriteId: "116", image: "116_Stuhl", word: "Der Stuhl", group: "haus" },
      { spriteId: "117", image: "117_Tisch", word: "Der Tisch", group: "haus" },
      { spriteId: "118", image: "118_Sofa", word: "Das Sofa", group: "sofa" },
      { spriteId: "119", image: "119_Schrank", word: "Der Schrank", group: "haus" },
      { spriteId: "120", image: "120_WC", word: "Das WC", group: "zaehneputzen" },
      { spriteId: "121", image: "121_Badewanne", word: "Die Badewanne", group: "zaehneputzen" },
      { spriteId: "122", image: "122_Dusche", word: "Die Dusche", group: "zaehneputzen" },
      { spriteId: "123", image: "123_Herd", word: "Der Herd", group: "sofa" },
      { spriteId: "124", image: "124_Kühlschrank", word: "Der Kühlschrank", group: "sofa" },
      { spriteId: "125", image: "125_Garage", word: "Die Garage", group: "sofa" },
      { spriteId: "126", image: "126_Teppich", word: "Der Teppich", group: "zaehneputzen" },
      { spriteId: "127", image: "127_Bett", word: "Das Bett", group: "zaehneputzen" },
      { spriteId: "128", image: "128_Kissen", word: "Das Kissen", group: "zaehneputzen" },
      { spriteId: "129", image: "129_Bettdecke", word: "Die Bettdecke", group: "zaehneputzen" },
      { spriteId: "130", image: "130_Lampe", word: "Die Lampe", group: "zaehneputzen" },
      { spriteId: "131", image: "131_Buch", word: "Das Buch", group: "sofa" },
      { spriteId: "132", image: "132_Computer", word: "Der Computer", group: "sofa" },
      { spriteId: "133", image: "133_Haus", word: "Das Haus", group: "haus" },
      { spriteId: "134", image: "134_Kuchen", word: "Der Kuchen", group: "sofa" },
      { spriteId: "135", image: "135_Der Backofen", word: "Der Backofen", group: "sofa" },
      { spriteId: "136", image: "136_Junge", word: "Der Junge", group: "familie" },
      { spriteId: "137", image: "137_Mädchen", word: "Das Mädchen", group: "familie" },
      { spriteId: "138", image: "138_Kleider", word: "Die Kleider", group: "sofa" },
      { spriteId: "139", image: "139_Regal", word: "Das Regal", group: "sofa" },
      { spriteId: "140", image: "140_Familie", word: "Die Familie", group: "familie" },
      { spriteId: "141", image: "141_Eltern", word: "Die Eltern", group: "familie" },
      { spriteId: "142", image: "142_Geburtstag", word: "Der Geburtstag", group: "sofa" },
      { spriteId: "143", image: "143_Hände_waschen", word: "Hände waschen", group: "zaehneputzen" },
      { spriteId: "144", image: "144_Zähne_putzen", word: "Zähne putzen", group: "zaehneputzen" },
      { spriteId: "145", image: "145_wohnen", word: "Wohnen", group: "familie" },
      { spriteId: "146", image: "146_Die Schere", word: "Die Schere", group: "buntstifte" },
      { spriteId: "147", image: "147_Der Leim", word: "Der Leim", group: "buntstifte" },
      { spriteId: "148", image: "148_Das Blatt Papier", word: "Das Blatt Papier", group: "buntstifte" },
      { spriteId: "149", image: "149_Die Buntstifte", word: "Die Buntstifte", group: "buntstifte" },
      { spriteId: "150", image: "150_Der Bleistift", word: "Der Bleistift", group: "buntstifte" },
      { spriteId: "151", image: "151_Der Pinsel", word: "Der Pinsel", group: "buntstifte" },
      { spriteId: "152", image: "152_Die Wasserfarben", word: "Die Wasserfarben", group: "buntstifte" },
      { spriteId: "153", image: "153_Der Spitzer", word: "Der Spitzer", group: "buntstifte" },
      { spriteId: "154", image: "154_Der Würfel", word: "Der Würfel", group: "buntstifte" },
      { spriteId: "155", image: "155_Das Spiel", word: "Das Spiel", group: "buntstifte" },
      { spriteId: "156", image: "156_zeichnen", word: "Zeichnen", group: "zeichnen" },
      { spriteId: "157", image: "157_spielen", word: "Spielen", group: "zeichnen" },
      { spriteId: "158", image: "158_basteln", word: "Basteln", group: "zeichnen" },
      { spriteId: "159", image: "159_schneiden", word: "Schneiden", group: "zeichnen" },
      { spriteId: "160", image: "160_helfen", word: "Helfen", group: "zeichnen" },
      { spriteId: "161", image: "161_singen", word: "Singen", group: "zeichnen" },
      { spriteId: "162", image: "162_lernen", word: "Lernen", group: "zeichnen" },
      { spriteId: "163", image: "163_bauen", word: "Bauen", group: "zeichnen" },
      { spriteId: "164", image: "164_turnen", word: "Turnen", group: "zeichnen" },
      { spriteId: "165", image: "165_tanzen", word: "Tanzen", group: "zeichnen" },
      { spriteId: "166", image: "166_malen", word: "Malen", group: "zeichnen" },
      { spriteId: "167", image: "167_kleben", word: "Kleben", group: "zeichnen" },
      // { spriteId: "852", image: "852_spitzig", word: "Spitzig", group: "zeichnen" }, // todo audio
    ]
  },
  {
    name: "koerper",
    words: [
      { spriteId: "201", image: "201_Der Kopf", word: "Der Kopf", group: "auge" },
      { spriteId: "202", image: "202_Der Hals", word: "Der Hals", group: "auge" },
      { spriteId: "203", image: "203_Der Arm", word: "Der Arm", group: "auge" },
      { spriteId: "204", image: "204_Das Bein", word: "Das Bein", group: "auge" },
      { spriteId: "205", image: "205_Der Fuss", word: "Der Fuss", group: "auge" },
      { spriteId: "206", image: "206_Der Bauch", word: "Der Bauch", group: "auge" },
      { spriteId: "207", image: "207_Der Rücken", word: "Der Rücken", group: "auge" },
      { spriteId: "208", image: "208_Der Po", word: "Der Po", group: "auge" },
      { spriteId: "209", image: "209_Die Hand", word: "Die Hand", group: "auge" },
      { spriteId: "210", image: "210_Der Finger", word: "Der Finger", group: "auge" },
      { spriteId: "211", image: "211_Das Ohr", word: "Das Ohr", group: "mund" },
      { spriteId: "212", image: "212_Das Auge", word: "Das Auge", group: "auge" },
      { spriteId: "213", image: "213_Das Gesicht", word: "Das Gesicht", group: "mund" },
      { spriteId: "214", image: "214_Die Haare", word: "Die Haare", group: "mund" },
      { spriteId: "215", image: "215_Die Zunge", word: "Die Zunge", group: "mund" },
      { spriteId: "216", image: "216_Der Mund", word: "Der Mund", group: "mund" },
      { spriteId: "217", image: "217_Die Nase", word: "Die Nase", group: "mund" },
      { spriteId: "218", image: "218_Das Nastuch", word: "Das Nastuch", group: "mund" },
      { spriteId: "219", image: "219_Der Zahn", word: "Der Zahn", group: "seife" },
      { spriteId: "220", image: "220_Die Zahnbürste", word: "Die Zahnbürste", group: "seife" },
      { spriteId: "221", image: "221_Die Zahnpasta", word: "Die Zahnpasta", group: "seife" },
      { spriteId: "222", image: "222_Das Blut", word: "Das Blut", group: "seife" },
      { spriteId: "223", image: "223_Das Pflaster", word: "Das Pflaster", group: "seife" },
      { spriteId: "224", image: "224_Die Seife", word: "Die Seife", group: "seife" },
      { spriteId: "225", image: "225_Das Handtuch", word: "Das Handtuch", group: "seife" },
      { spriteId: "226", image: "226_Lachen", word: "Lachen", group: "mund" },
      { spriteId: "227", image: "227_Weinen", word: "Weinen", group: "mund" },
      { spriteId: "819", image: "819_trocken", word: "Trocken", group: "seife" },
      { spriteId: "820", image: "820_nass", word: "Nass", group: "seife" },
      { spriteId: "838", image: "838_gehen", word: "Gehen", group: "seife" },
      { spriteId: "839", image: "839_schlafen", word: "Schlafen", group: "seife" },
      { spriteId: "801", image: "801_gross", word: "Gross", group: "gross" },
      { spriteId: "802", image: "802_klein", word: "Klein", group: "gross" },
      { spriteId: "803", image: "803_schön", word: "Schön", group: "gross" },
      { spriteId: "804", image: "804_hässlich", word: "Hässlich", group: "gross" },
      { spriteId: "811", image: "811_rund", word: "Rund", group: "gross" },
      { spriteId: "812", image: "812_eckig", word: "Eckig", group: "gross" },
      { spriteId: "815", image: "815_lang", word: "Lang", group: "gross" },
      { spriteId: "816", image: "816_kurz", word: "Kurz", group: "gross" },
      { spriteId: "817", image: "817_stark", word: "Stark", group: "gross" },
      { spriteId: "818", image: "818_schwach", word: "Schwach", group: "gross" },
      { spriteId: "840", image: "840_zählen", word: "Zählen", group: "gross" },
      { spriteId: "841", image: "841_riechen", word: "Riechen", group: "geben" },
      { spriteId: "842", image: "842_sehen", word: "Sehen", group: "geben" },
      { spriteId: "843", image: "843_hören", word: "Hören", group: "geben" },
      { spriteId: "844", image: "844_geben", word: "Geben", group: "geben" },
      { spriteId: "845", image: "845_rufen", word: "Rufen", group: "geben" },
      { spriteId: "846", image: "846_sprechen", word: "Sprechen", group: "geben" },
      { spriteId: "850", image: "850_schreien", word: "Schreien", group: "geben" },
      { spriteId: "851", image: "851_warten", word: "Warten", group: "geben" },
      { spriteId: "854", image: "854_lustig", word: "Lustig", group: "geben" },
      { spriteId: "859", image: "859_kalt", word: "Kalt (empfinden)", group: "geben" },
      { spriteId: "860", image: "860_warm", word: "Warm (empfinden)", group: "geben" },
      { spriteId: "260", image: "1_Finger", word: "1, Eins", group: "zahlen" },
      { spriteId: "261", image: "2_Finger", word: "2, Zwei", group: "zahlen" },
      { spriteId: "262", image: "3_Finger", word: "3, Drei", group: "zahlen" },
      { spriteId: "263", image: "4_Finger", word: "4, Vier", group: "zahlen" },
      { spriteId: "264", image: "5_Finger", word: "5, Fünf", group: "zahlen" },
      { spriteId: "265", image: "6_Finger", word: "6, Sechs", group: "zahlen" },
      { spriteId: "266", image: "7_Finger", word: "7, Sieben", group: "zahlen" },
      // todo 228 & 229 glücklich sein und traurig sein
    ]
  },
  {
    name: "kleider",
    words: [
      { spriteId: "301", image: "301_Die Hose", word: "Die Hose", group: "hose" },
      { spriteId: "302", image: "302_Die Jacke", word: "Die Jacke", group: "hose" },
      { spriteId: "303", image: "303_Die Mütze", word: "Die Mütze", group: "hose" },
      { spriteId: "304", image: "304_Die Schuhe", word: "Die Schuhe", group: "hose" },
      { spriteId: "305", image: "305_Die Socken", word: "Die Socken", group: "hose" },
      { spriteId: "306", image: "306_Die Finken", word: "Die Finken", group: "hose" },
      { spriteId: "307", image: "307_Der Pullover", word: "Der Pullover", group: "hose" },
      { spriteId: "308", image: "308_Das T-Shirt", word: "Das T-Shirt", group: "hose" },
      { spriteId: "309", image: "309_Das Pyjama", word: "Das Pyjama", group: "hose" },
      { spriteId: "310", image: "310_Die Brille", word: "Die Brille", group: "hose" },
      { spriteId: "311", image: "311_Die Tasche", word: "Die Tasche", group: "hose" },
      { spriteId: "312", image: "312_Die Strumpfhose", word: "Die Strumpfhose", group: "handschuhe" },
      { spriteId: "313", image: "313_Die Unterhose", word: "Die Unterhose", group: "stiefel" },
      { spriteId: "314", image: "314_Das Unterhemd", word: "Das Unterhemd", group: "stiefel" },
      { spriteId: "315", image: "315_Das Kleid", word: "Das Kleid", group: "hose" },
      { spriteId: "316", image: "316_Die Handschuhe", word: "Die Handschuhe", group: "handschuhe" },
      { spriteId: "317", image: "317_Der Schal", word: "Der Schal", group: "handschuhe" },
      { spriteId: "318", image: "318_Der Skianzug", word: "Der Skianzug", group: "handschuhe" },
      { spriteId: "319", image: "319_Der Reisverschluss", word: "Der Reisverschluss", group: "handschuhe" },
      { spriteId: "320", image: "320_Der Knopf", word: "Der Knopf", group: "handschuhe" },
      { spriteId: "321", image: "321_anziehen", word: "Anziehen", group: "stiefel" },
      { spriteId: "322", image: "322_ausziehen", word: "Ausziehen", group: "stiefel" },
      { spriteId: "323", image: "323_gepunktet", word: "Gepunktet", group: "handschuhe" },
      { spriteId: "324", image: "324_gestreift", word: "Gestreift", group: "handschuhe" },
      { spriteId: "325", image: "325_Der Rucksack", word: "Der Rucksack", group: "hose" },
      { spriteId: "326", image: "326_Die Turnhose", word: "Die Turnhose", group: "hose" },
      { spriteId: "327", image: "327_Das Badekleid", word: "Das Badekleid", group: "hose" },
      { spriteId: "328", image: "328_Die Badehose", word: "Die Badehose", group: "hose" },
      { spriteId: "329", image: "329_Die Regenjacke", word: "Die Regenjacke", group: "hose" },
      { spriteId: "330", image: "330_Die Stiefel", word: "Die Stiefel", group: "hose" },
      { spriteId: "331", image: "331_Der Regenschirm", word: "Der Regenschirm", group: "hose" },
      { spriteId: "332", image: "332_rot", word: "Rot", group: "bunt" },
      { spriteId: "333", image: "333_gelb", word: "Gelb", group: "bunt" },
      { spriteId: "334", image: "334_grün", word: "Grün", group: "bunt" },
      { spriteId: "335", image: "335_blau", word: "Blau", group: "bunt" },
      { spriteId: "336", image: "336_orange", word: "Orange", group: "bunt" },
      { spriteId: "337", image: "337_braun", word: "Braun", group: "bunt" },
      { spriteId: "338", image: "338_schwarz", word: "Schwarz", group: "bunt" },
      { spriteId: "339", image: "339_weiss", word: "Weiss", group: "bunt" },
      { spriteId: "340", image: "340_lila", word: "Lila", group: "bunt" },
      { spriteId: "341", image: "341_bunt", word: "Farbig", group: "bunt" },
      { spriteId: "342", image: "342_grau", word: "Grau", group: "bunt" },
      { spriteId: "343", image: "343_Die Kapuze", word: "Die Kapuze", group: "hose" },
      //{ spriteId: "344", image: "344_rot gelb grün", word: "Rot, Gelb, Grün", group: "bunt" }, // todo Audio
      { spriteId: "807", image: "807_hoch", word: "Hoch", group: "handschuhe" },
      { spriteId: "808", image: "808_tief", word: "Tief", group: "handschuhe" },
      { spriteId: "809", image: "809_hart", word: "Hart", group: "handschuhe" },
      { spriteId: "810", image: "810_weich", word: "Weich", group: "handschuhe" },
    ]
  },
  {
    name: "essen",
    words: [
      { spriteId: "401", image: "401_Der Apfel", word: "Der Apfel", group: "apfel" },
      { spriteId: "402", image: "402_Die Birne", word: "Die Birne", group: "apfel" },
      { spriteId: "403", image: "403_Die Banane", word: "Die Banane", group: "apfel" },
      { spriteId: "404", image: "404_Die Erdbeere", word: "Die Erdbeeren", group: "apfel" },
      { spriteId: "405", image: "405_Die Orange", word: "Die Orange", group: "apfel" },
      { spriteId: "406", image: "406_Die Kartoffel", word: "Die Kartoffel", group: "apfel" },
      { spriteId: "407", image: "407_Die Karotte", word: "Die Karotte", group: "apfel" },
      { spriteId: "408", image: "408_Der Salat", word: "Der Salat", group: "apfel" },
      { spriteId: "409", image: "409_Die Tomate", word: "Die Tomate", group: "apfel" },
      { spriteId: "410", image: "410_Die Zwiebel", word: "Die Zwiebel", group: "apfel" },
      { spriteId: "411", image: "411_Die Wurst", word: "Die Wurst", group: "apfel" },
      { spriteId: "412", image: "412_Das Brot", word: "Das Brot", group: "joghurt" },
      { spriteId: "413", image: "413_Das Brötchen", word: "Das Brötchen", group: "joghurt" },
      { spriteId: "414", image: "414_Das Joghurt", word: "Das Joghurt", group: "joghurt" },
      { spriteId: "415", image: "415_Die Butter", word: "Die Butter", group: "joghurt" },
      { spriteId: "416", image: "416_Der Saft", word: "Der Saft", group: "joghurt" },
      { spriteId: "417", image: "417_Das Wasser", word: "Das Wasser", group: "joghurt" },
      { spriteId: "418", image: "418_Die Chips", word: "Die Chips", group: "joghurt" },
      { spriteId: "419", image: "419_Das Salz", word: "Das Salz", group: "joghurt" },
      { spriteId: "420", image: "420_Der Zucker", word: "Der Zucker", group: "joghurt" },
      { spriteId: "421", image: "421_Der Tee", word: "Der Tee", group: "joghurt" },
      { spriteId: "422", image: "422_Die Konfitüre", word: "Die Konfitüre", group: "joghurt" },
      { spriteId: "423", image: "423_Die Schokolade", word: "Die Schokolade", group: "glace" },
      { spriteId: "424", image: "424_Das Glace", word: "Das Glace", group: "glace" },
      { spriteId: "425", image: "425_Die Pizza", word: "Die Pizza", group: "glace" },
      { spriteId: "426", image: "426_Die Spaghetti", word: "Die Spaghetti", group: "glace" },
      { spriteId: "427", image: "427_Der Teller", word: "Der Teller", group: "glace" },
      { spriteId: "428", image: "428_Die Tasse", word: "Die Tasse", group: "glace" },
      { spriteId: "429", image: "429_Das Glas", word: "Das Glas", group: "glace" },
      { spriteId: "430", image: "430_Der Löffel", word: "Der Löffel", group: "glace" },
      { spriteId: "431", image: "431_Die Gabel", word: "Die Gabel", group: "glace" },
      { spriteId: "432", image: "432_Das Messer", word: "Das Messer", group: "glace" },
      { spriteId: "433", image: "433_kochen", word: "Kochen", group: "glace" },
      { spriteId: "434", image: "434_essen", word: "Essen", group: "trinken" },
      { spriteId: "435", image: "435_trinken", word: "Trinken", group: "trinken" },
      { spriteId: "436", image: "436_Der Hunger", word: "Der Hunger", group: "trinken" },
      { spriteId: "437", image: "437_Die Schale", word: "Die Schale", group: "trinken" },
      { spriteId: "438", image: "438_schmeckt gut", word: "Schmeckt gut", group: "trinken" },
      { spriteId: "439", image: "439_Die Suppe", word: "Die Suppe", group: "trinken" },
      { spriteId: "440", image: "440_süss", word: "Süss", group: "trinken" },
      { spriteId: "441", image: "441_scharf", word: "Scharf", group: "trinken" },
      // todo { spriteId: "442", image: "442_heiss", word: "Heiss", group: "trinken" }, // Sprite noch machen
      // todo { spriteId: "443", image: "443_kalt", word: "Kalt", group: "trinken" }, // Sprite noch machen
      // todo { spriteId: "444", image: "444_schneiden", word: "Schneiden", group: "trinken" }, // Ton fehlt
      { spriteId: "813", image: "813_viel", word: "Viel", group: "viel" },
      { spriteId: "814", image: "814_wenig", word: "Wenig", group: "viel" },
      { spriteId: "821", image: "821_gut", word: "Gut", group: "viel" },
      { spriteId: "822", image: "822_schlecht", word: "Schlecht", group: "viel" },
      { spriteId: "830", image: "830_krumm", word: "Krumm", group: "viel" },
      { spriteId: "855", image: "855_schmutzig", word: "Schmutzig", group: "viel" },
      { spriteId: "856", image: "856_sauber", word: "Sauber", group: "viel" },
      { spriteId: "857", image: "857_dünn", word: "Dünn", group: "viel" },
      { spriteId: "858", image: "858_dick", word: "Dick", group: "viel" },
    ]
  },
  {
    name: "natur",
    words: [
      { spriteId: "501", image: "501_Der Baum", word: "Der Baum", group: "blume" },
      { spriteId: "502", image: "502_Die Blume", word: "Die Blume", group: "blume" },
      { spriteId: "503", image: "503_Das Blatt", word: "Das Blatt", group: "blume" },
      { spriteId: "504", image: "504_Die Erde", word: "Die Erde", group: "blume" },
      { spriteId: "505", image: "505_Der Pilz", word: "Der Pilz", group: "blume" },
      { spriteId: "506", image: "506_Der Wald", word: "Der Wald", group: "blume" },
      { spriteId: "507", image: "507_Der Igel", word: "Der Igel", group: "blume" },
      { spriteId: "508", image: "508_Der Hase", word: "Der Hase", group: "hase" },
      { spriteId: "509", image: "509_Das Eichhörnchen", word: "Das Eichhörnchen", group: "blume" },
      { spriteId: "510", image: "510_Der Fuchs", word: "Der Fuchs", group: "blume" },
      { spriteId: "511", image: "511_Der Vogel", word: "Der Vogel", group: "blume" },
      { spriteId: "512", image: "512_Die Maus", word: "Die Maus", group: "hase" },
      { spriteId: "513", image: "513_Das Nest", word: "Das Nest", group: "hase" },
      { spriteId: "514", image: "514_Die Schnecke", word: "Die Schnecke", group: "hase" },
      { spriteId: "515", image: "515_Die Spinne", word: "Die Spinne", group: "hase" },
      { spriteId: "516", image: "516_Die Ameise", word: "Die Ameise", group: "hase" },
      { spriteId: "517", image: "517_Die Biene", word: "Die Biene", group: "frosch" },
      { spriteId: "518", image: "518_Der Wurm", word: "Der Wurm", group: "hase" },
      { spriteId: "519", image: "519_Der Käfer", word: "Der Käfer", group: "hase" },
      { spriteId: "520", image: "520_Der Schmetterling", word: "Der Schmetterling", group: "hase" },
      { spriteId: "521", image: "521_Die Wiese", word: "Die Wiese", group: "frosch" },
      { spriteId: "522", image: "522_Der Fluss", word: "Der Fluss", group: "frosch" },
      { spriteId: "523", image: "523_Der See", word: "Der See", group: "frosch" },
      { spriteId: "524", image: "524_Der Fisch", word: "Der Fisch", group: "frosch" },
      { spriteId: "525", image: "525_Der Frosch", word: "Der Frosch", group: "frosch" },
      { spriteId: "526", image: "526_Die Ente", word: "Die Ente", group: "frosch" },
      { spriteId: "527", image: "527_Die Schlange", word: "Die Schlange", group: "hase" },
      { spriteId: "528", image: "528_Der Ast", word: "Der Ast", group: "hase" },
      { spriteId: "529", image: "529_Der Stein", word: "Der Stein", group: "hase" },
      { spriteId: "530", image: "530_Die Nuss", word: "Die Nuss", group: "frosch" },
      { spriteId: "531", image: "531_Der Berg", word: "Der Berg", group: "blume" },
      { spriteId: "532", image: "532_Der Stachel", word: "Der Stachel", group: "frosch" },
      { spriteId: "533", image: "533_Der Honig", word: "Der Honig", group: "frosch" },
      { spriteId: "534", image: "534_Der Boden", word: "Der Boden", group: "blume" },
      { spriteId: "535", image: "535_Die Schale", word: "Die Schale", group: "frosch" },
      // todo frosch 536 Die Blumen
      { spriteId: "805", image: "805_schnell", word: "Schnell", group: "oben" },
      { spriteId: "806", image: "806_langsam", word: "Langsam", group: "oben" },
      { spriteId: "825", image: "825_hinten", word: "Hinten", group: "oben" },
      { spriteId: "826", image: "826_vorne", word: "Vorne", group: "oben" },
      { spriteId: "827", image: "827_oben", word: "Oben", group: "oben" },
      { spriteId: "828", image: "828_unten", word: "Unten", group: "oben" },
      { spriteId: "829", image: "829_auf", word: "Auf", group: "oben" },
      //{ spriteId: "829a", image: "829a_unter", word: "Unter", group: "oben" }, // todo audio fehlt
      { spriteId: "834", image: "834_fliegen", word: "Fliegen", group: "oben" },
      { spriteId: "835", image: "835_kriechen", word: "Kriechen", group: "oben" },
      { spriteId: "836", image: "836_hüpfen", word: "Hüpfen", group: "oben" },
      { spriteId: "837", image: "837_schwimmen", word: "Schwimmen", group: "oben" },
    ]
  },
  {
    name: "bauernhof",
    words: [
      { spriteId: "601", image: "601_Die Kuh", word: "Die Kuh", group: "schwein" },
      { spriteId: "602", image: "602_Das Huhn", word: "Das Huhn", group: "schwein" },
      { spriteId: "603", image: "603_Der Hahn", word: "Der Hahn", group: "schwein" },
      { spriteId: "604", image: "604_Das Küken", word: "Das Küken", group: "schwein" },
      { spriteId: "605", image: "605_Die Ziege", word: "Die Ziege", group: "schwein" },
      { spriteId: "606", image: "606_Das Schaf", word: "Das Schaf", group: "schwein" },
      { spriteId: "607", image: "607_Das Schwein", word: "Das Schwein", group: "schwein" },
      { spriteId: "608", image: "608_Der Hund", word: "Der Hund", group: "katze" },
      { spriteId: "609", image: "609_Die Katze", word: "Die Katze", group: "katze" },
      { spriteId: "610", image: "610_Der Esel", word: "Der Esel", group: "schwein" },
      { spriteId: "611", image: "611_Das Pferd", word: "Das Pferd", group: "schwein" },
      { spriteId: "612", image: "612_Das Fell", word: "Das Fell", group: "katze" },
      { spriteId: "613", image: "613_Das Futter", word: "Das Futter", group: "traktor" },
      { spriteId: "614", image: "614_Der Stall", word: "Der Stall", group: "traktor" },
      { spriteId: "615", image: "615_Das Ei", word: "Das Ei", group: "schwein" },
      { spriteId: "616", image: "616_Der Traktor", word: "Der Traktor", group: "traktor" },
      { spriteId: "617", image: "617_Der Zaun", word: "Der Zaun", group: "traktor" },
      { spriteId: "618", image: "618_Der Käse", word: "Der Käse", group: "traktor" },
      { spriteId: "619", image: "619_Die Milch", word: "Die Milch", group: "traktor" },
      { spriteId: "620", image: "620_Das Bauernhaus", word: "Das Bauernhaus", group: "traktor" },
      { spriteId: "621", image: "621_Das Gras", word: "Das Gras", group: "traktor" },
      { spriteId: "622", image: "622_Die Tiere", word: "Das Tier", group: "traktor" },
      { spriteId: "650", image: "650_Die Baustelle", word: "Die Baustelle", group: "kran" },
      { spriteId: "651", image: "651_Der Kran", word: "Der Kran", group: "kran" },
      { spriteId: "652", image: "652_Das Auto", word: "Das Auto", group: "kran" },
      { spriteId: "653", image: "653_Das Velo", word: "Das Velo", group: "kran" },
      { spriteId: "654", image: "654_Der Bus", word: "Der Bus", group: "kran" },
      { spriteId: "655", image: "655_Das Motorrad", word: "Das Motorrad", group: "kran" },
      { spriteId: "656", image: "656_Das Flugzeug", word: "Das Flugzeug", group: "kran" },
      { spriteId: "657", image: "657_Der Zug", word: "Der Zug", group: "kran" },
      { spriteId: "658", image: "658_Das Schiff", word: "Das Schiff", group: "kran" },
      { spriteId: "659", image: "659_Die Strasse", word: "Die Strasse", group: "kran" },
      { spriteId: "660", image: "660_Der Bahnhof", word: "Der Bahnhof", group: "kran" },
      { spriteId: "661", image: "661_warten_schauen_gehen", word: "Warten, schauen, hören, gehen", group: "kran" },
      { spriteId: "662", image: "662_Das Trottoir", word: "Das Trottoir", group: "kran" },
      { spriteId: "663", image: "663_Der Bagger", word: "Der Bagger", group: "kran" },
      { spriteId: "823", image: "823_laut", word: "Laut", group: "katze" },
      { spriteId: "824", image: "824_leise", word: "Leise", group: "katze" },
      { spriteId: "847", image: "847_bellen", word: "Bellen", group: "katze" },
      { spriteId: "848", image: "848_miauen", word: "Miauen", group: "katze" },
      { spriteId: "849", image: "849_fahren", word: "Fahren", group: "traktor" },
      { spriteId: "853", image: "853_lieb", word: "Lieb", group: "katze" },
      //{ spriteId: "853a", image: "853a_böse", word: "Böse", group: "katze" }, // todo Audio fehlt
    ]
  },
  {
    name: "jahr",
    words: [
      { spriteId: "701", image: "701_Der Sommer", word: "Der Sommer", group: "sonne" },
      { spriteId: "702", image: "702_Der Herbst", word: "Der Herbst", group: "sonne" },
      { spriteId: "703", image: "703_Der Winter", word: "Der Winter", group: "sonne" },
      { spriteId: "704", image: "704_Der Frühling", word: "Der Frühling", group: "sonne" },
      { spriteId: "705", image: "705_Die Sonne", word: "Die Sonne", group: "sonne" },
      { spriteId: "706", image: "706_Die Wolke", word: "Die Wolke", group: "sonne" },
      { spriteId: "707", image: "707_Der Regen", word: "Der Regen", group: "sonne" },
      { spriteId: "708", image: "708_Der Schnee", word: "Der Schnee", group: "sonne" },
      { spriteId: "709", image: "709_Der Wind", word: "Der Wind", group: "sonne" },
      { spriteId: "710", image: "710_Der Mond", word: "Der Mond", group: "sonne" },
      { spriteId: "711", image: "711_Der Nebel", word: "Der Nebel", group: "geschenk" },
      { spriteId: "712", image: "712_Der Regenbogen", word: "Der Regenbogen", group: "geschenk" },
      { spriteId: "713", image: "713_Das Gewitter", word: "Das Gewitter", group: "geschenk" },
      { spriteId: "714", image: "714_Die Schneeflocke", word: "Die Schneeflocke", group: "geschenk" },
      { spriteId: "715", image: "715_Der Osterhase", word: "Der Osterhase", group: "geschenk" },
      { spriteId: "716", image: "716_Das Osterei", word: "Das Osterei", group: "geschenk" },
      { spriteId: "717", image: "717_Die Weihnachten", word: "Die Weihnachten", group: "geschenk" },
      { spriteId: "718", image: "718_Der Weihnachtsbaum", word: "Der Weihnachtsbaum", group: "geschenk" },
      { spriteId: "719", image: "719_Das Geschenk", word: "Das Geschenk", group: "geschenk" },
      { spriteId: "720", image: "720_Der Samiklaus", word: "Der Samiklaus", group: "geschenk" },
      { spriteId: "721", image: "721_Der Engel", word: "Der Engel", group: "geschenk" },
      { spriteId: "722", image: "722_Der Schneemann", word: "Der Schneemann", group: "stern" },
      { spriteId: "723", image: "723_Der Schneeball", word: "Der Schneeball", group: "stern" },
      { spriteId: "724", image: "724_Der Schlitten", word: "Der Schlitten", group: "stern" },
      { spriteId: "725", image: "725_Die Schaufel", word: "Die Schaufel", group: "stern" },
      { spriteId: "726", image: "726_Die Ski", word: "Die Ski", group: "stern" },
      { spriteId: "727", image: "727_Die Schlittschuhe", word: "Die Schlittschuhe", group: "stern" },
      { spriteId: "728", image: "728_Der Stern", word: "Der Stern", group: "stern" },
      { spriteId: "729", image: "729_Die Kerze", word: "Die Kerze", group: "stern" },
      { spriteId: "730", image: "730_Der Ball", word: "Der Ball", group: "ball" },
      { spriteId: "731", image: "731_Der Sandkasten", word: "Der Sandkasten", group: "ball" },
      { spriteId: "732", image: "732_spielen", word: "Spielen", group: "ball" },
      { spriteId: "733", image: "733_bauen", word: "Bauen", group: "ball" },
      { spriteId: "734", image: "734_rennen", word: "Rennen", group: "ball" },
      { spriteId: "735", image: "735_spazieren", word: "Spazieren", group: "ball" },
      { spriteId: "736", image: "736_Der Himmel", word: "Der Himmel", group: "stern" },
      { spriteId: "737", image: "737_Das Eis", word: "Das Eis", group: "stern" },
      { spriteId: "738", image: "738_Der Tag", word: "Der Tag", group: "ball" },
      { spriteId: "739", image: "739_Die Nacht", word: "Die Nacht", group: "ball" },
      { spriteId: "831", image: "831_an", word: "An", group: "ball" },
      //{ spriteId: "831a", image: "831a_aus", word: "Aus", group: "ball" }, // todo Audio fehlt
      { spriteId: "832", image: "832_hell", word: "Hell", group: "ball" },
      { spriteId: "833", image: "833_dunkel", word: "Dunkel", group: "ball" },
    ]
  },
];
