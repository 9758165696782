import { ISprite } from "../interfaces";

import zuhause from "../assets/Uebungen/zuhause/words.mp3";
import koerper from "../assets/Uebungen/koerper/words.mp3";
import kleider from "../assets/Uebungen/kleider/words.mp3";
import essen from "../assets/Uebungen/essen/words.mp3";
import natur from "../assets/Uebungen/natur/words.mp3";
import bauernhof from "../assets/Uebungen/bauernhof/words.mp3";
import jahr from "../assets/Uebungen/jahr/words.mp3";
	
export const WORDSAUDIO: Array<ISprite> = [
	{
		name: "zuhause",
		url: zuhause,
		sprite: {
	      101: [0, 5000],
	      102: [5000, 5000],
	      103: [10000, 2100],
	      104: [12500, 2900],
	      105: [15400, 3800],
	      106: [19200, 3000],
	      107: [135500, 2000],
	      108: [23000, 3000],
	      109: [26100, 2200], 
	      110: [28100, 2400],
	      111: [30600, 3400],
	      112: [34100, 3900],
	      113: [38100, 3900],
	      114: [42000, 3000],
	      115: [45500, 2400],
	      116: [48500, 2500],
	      117: [52000, 2000],
	      118: [55000, 3000],
	      119: [59000, 2500],
	      120: [62200, 2000],
	      121: [65000, 3000],
	      122: [68000, 2000],
	      123: [71000, 2000],
	      124: [74000, 3000],
	      125: [78500, 2500],
	      126: [81000, 3000],
	      127: [84000, 2100],
	      128: [87000, 2100],
	      129: [90000, 3000],
	      130: [93500, 2000],
	      131: [95200, 2300],
	      132: [98000, 2200],
	      133: [101000, 1500],
	      134: [102500, 2500],
	      135: [138500, 2000],
	      136: [107200, 3000],
	      137: [111000, 2000],
	      138: [114000, 1500],
	      139: [116000, 2000],
	      140: [118000, 2300],
	      141: [121000, 2400],
	      142: [124000, 2500],
	      143: [127100, 2000],
	      144: [130000, 2000],
	      145: [141000, 1800],
	      146: [143400, 1600],
	      147: [146000, 1500],
	      148: [148000, 3200],
	      149: [152000, 2800],
	      150: [156000, 2200],
	      151: [159000, 2200],
	      152: [162000, 3000],
	      153: [166000, 2000],
	      154: [169000, 2000],
	      155: [172400, 2000],
	      156: [175300, 2000],
	      157: [178300, 2000],
	      158: [181000, 2500],
	      159: [185000, 2200],
	      160: [188800, 1600],
	      161: [191000, 2000],
	      162: [193500, 1500],
	      163: [196000, 2000],
	      164: [199000, 2000],
	      165: [201000, 2000],
	      166: [203000, 3000],
	      167: [206500, 1800],
	    }
	},
	{
		name: "koerper",
		url: koerper,
		sprite: {
			201: [500, 1500],
			202: [2000, 1800],
			203: [4500, 1500],
			204: [6500, 1800],
			205: [8500, 2000],
			206: [11000, 2000],
			207: [13000, 2300],
			208: [15500, 1500],
			209: [17300, 2000],
			210: [20000, 2500],
			211: [23000, 2000],
			212: [25000, 2000],
			213: [28000, 2000],
			214: [31000, 1500],
			215: [32500, 3000],
			216: [35500, 1500],
			217: [38000, 2000],
			218: [40500, 2500], 
			219: [44200, 1700], 
			220: [46200, 2500], 
			221: [49000, 2500],
			222: [52000, 2000],
			223: [54300, 2200], 
			224: [57200, 1800],
			225: [59200, 2000],
			226: [62000, 1500],
			227: [64500, 1500],
			801: [71800, 1500],
			802: [73500, 2000],
			803: [75700, 1800],
			804: [77500, 1800],
			811: [79500, 1500],
			812: [81300, 1700],
			815: [83000, 1500],
			816: [85000, 1500],
			817: [87200, 1800],
			818: [89500, 1500],
			819: [91900, 1400],
			820: [94000, 1500],
			838: [96000, 1500],
			839: [98000, 2000],
			840: [101200, 1300],
			841: [103000, 2000],
			842: [106000, 1700],
			843: [108700, 1500],
			844: [111000, 2000],
			845: [113000, 2000],
			846: [115000, 2000],
			850: [118000, 2000],
			851: [120000, 2000],
			854: [122000, 2000],
			859: [124000, 2000],
			860: [126000, 2000],
			260: [128000, 1500],
			261: [130000, 1300],
			262: [132000, 1100],
			263: [133000, 1500],
			264: [135000, 1500],
			265: [136500, 1500],
			266: [138000, 2000],
		}
	},
	{
		name: "kleider",
		url: kleider,
		sprite: {
			301: [0, 1500],
			302: [1500, 1500],
			303: [3200, 1500],
			304: [4700, 2000],
			305: [6500, 1500],
			306: [8000, 2000],
			307: [10000, 2000],
			308: [12000, 2100],
			309: [15000, 1500],
			310: [17000, 1500],
			311: [19500, 1500],
			312: [21200, 2800],
			313: [24700, 2500],
			314: [28000, 2500],	
			315: [31500, 1500],
			316: [33500, 2000],
			317: [35700, 2300],
			318: [38700, 2000],
			319: [41000, 2300],
			320: [44000, 2000],
			321: [47000, 2000],
			322: [49000, 2000],
			323: [51700, 1700],
			324: [53900, 1800],
			325: [56500, 2100],
			326: [59000, 2400],
			327: [62000, 3000],
			328: [65500, 1800],
			329: [67200, 1800],
			330: [69500, 1800], 
			331: [71600, 2300], 
			332: [74200, 1300], 
			333: [76200, 1800], 
			334: [78200, 1800], 
			335: [80700, 1600], 
			336: [82500, 1900], 
			337: [87600, 1900], 
			338: [92200, 2000], 
			339: [95200, 1500],
			340: [97000, 2000], 
			341: [99500, 1500], 
			342: [101500, 1500],
			343: [103000, 3000],
			344: [103000, 3000],
			807: [106000, 1500],
			808: [108000, 1500],
			809: [109500, 1500],
			810: [111000, 2000],
		}
	},
	{
		name: "essen",
		url: essen,
		sprite: {
			401: [0, 1500],
			402: [1600, 1500],
			403: [3000, 2000],
			404: [5100, 2400],
			405: [7600, 1500],
			406: [9200, 1800],
			407: [11200, 1800],
			408: [13500, 2000],
			409: [15700, 2000],
			410: [18000, 2100],
			411: [20500, 1500],
			412: [23000, 2000],
			413: [25000, 2000],
			414: [27500, 1500],
			415: [29500, 1500],
			416: [31200, 1800],
			417: [33900, 1500], 
			418: [36000, 1500],
			419: [38500, 2000], 
			420: [41900, 1500],
			421: [43600, 1600], 
			422: [45500, 2000],
			423: [48000, 2000], 
			424: [50000, 2000],
			425: [53000, 1500], 
			426: [55000, 2000], 
			427: [57500, 1700],
			428: [59300, 1700],
			429: [61500, 2000],
			430: [64000, 1500], 
			431: [66000, 1500], 
			432: [67500, 2000], 
			433: [70700, 1400],
			434: [72000, 1500], 
			435: [73800, 1500],
			436: [75500, 1500],
			437: [78000, 2000], 
			438: [80000, 2500], 
			439: [83500, 1700], 
			440: [85200, 1800], 
			441: [87510, 1600], 
			//  heiss (essen) / Kalt (essen) / schneiden (Messer)
			813: [90000, 1600], 
			814: [92000, 1600], 
			821: [94000, 1500], 
			822: [96000, 1500], 
			830: [97500, 1500], 
			855: [99000, 2000], 
			856: [101500, 1500], 
			857: [104000, 1200], 
			858: [106000, 1200], 
		}
	},
	{
		name: "natur",
		url: natur,
		sprite: {
			501: [0, 1300],
			502: [1500, 1400],
			503: [3500, 1500],
			504: [5000, 1500],
			505: [6500, 1600],
			506: [8400, 1600],
			507: [10500, 1500],
			508: [12000, 1600],
			509: [14000, 1800],
			510: [16000, 1600],
			511: [17800, 1700],
			512: [20000, 1600],
			513: [22000, 2000],
			514: [24500, 2000],
			515: [27000, 3000],
			516: [30000, 2000],
			517: [33500, 1800],
			518: [35800, 1800],
			519: [38300, 2500],
			520: [41000, 2500],
			521: [44200, 2000],
			522: [47700, 2200],
			523: [49900, 1500],
			524: [51900, 1600],
			525: [54000, 1500],
			526: [55700, 1900],
			527: [58000, 1500],
			528: [60000, 2000],
			529: [62500, 2000],
			530: [65700, 1700],
			531: [69000, 1500],
			532: [72000, 1500],
			533: [74000, 3000],
			534: [78000, 2000],
			535: [80500, 1500],
			805: [83000, 1500],
			806: [84500, 2200],
			834: [86900, 2000],
			835: [89300, 2000],
			836: [91400, 2000],
			837: [93900, 2100],
			825: [96400, 1500],
			826: [97800, 1500],
			827: [99300, 1500],
			828: [101500, 1500],
			829: [103000, 1000],
		}
	},
	{
		name: "bauernhof",
		url: bauernhof,
		sprite: {
			601: [0, 1500],
			602: [1500, 1600],
			603: [3300, 2000],
			604: [5500, 1700],
			605: [7500, 1500],
			606: [9300, 2000],
			607: [12000, 1500],
			608: [14000, 1500],
			609: [15800, 1800],
			610: [18000, 2000],
			611: [21000, 1500],
			612: [23000, 2000],
			613: [26000, 2000],
			614: [29000, 2000],
			615: [31500, 2500],
			616: [34200, 2300],
			617: [37500, 1600],
			618: [40000, 1500],
			619: [42100, 1800],
			620: [44500, 2700],
			621: [48000, 2000],
			622: [52500, 1700],
			650: [55500, 2500],
			651: [58000, 2200],
			652: [60500, 2200],
			653: [63000, 2000],
			654: [66300, 1600],
			655: [69000, 2200],
			656: [72000, 2200],
			657: [74200, 1800],
			658: [77200, 1800],
			659: [79500, 1800],
			660: [81500, 2200],
			661: [84500, 4300],
			662: [95000, 2100],
			663: [98000, 2500],
			823: [101000, 2000],
			824: [105000, 2000],
			847: [108000, 1500],
			848: [109600, 1500],
			849: [112000, 1500],
			853: [113800, 1500],
		}
	},
	{
		name: "jahr",
		url: jahr,
		sprite: {
			701: [0, 1500],
			702: [2000, 2000],
			703: [4000, 2000],
			704: [6000, 2200],
			705: [9000, 1500],
			706: [11000, 2000],
			707: [13500, 1500],
			708: [15500, 1700],
			709: [18000, 1500],
			710: [20000, 2000],
			711: [22700, 1700],
			712: [25000, 2400],
			713: [28200, 2000],
			714: [31000, 2500],
			715: [34000, 2500],
			716: [37000, 3000],
			717: [41000, 1500],
			718: [43000, 3000],
			719: [46500, 1700],
			720: [48800, 2100],
			721: [51500, 1500],
			722: [53500, 2500],
			723: [56500, 2500],
			724: [60000, 1500],
			725: [62500, 2000],
			726: [65000, 2000],
			727: [68000, 2000],
			728: [74000, 2000],
			729: [77000, 2000],
			730: [79500, 2500],
			731: [83000, 3000],
			732: [87000, 2000],
			733: [90000, 1200],
			734: [93000, 1500],
			735: [95400, 2000],
			736: [98900, 1500],
			737: [102000, 2000],
			738: [105000, 2000],
			739: [109000, 2000],

			831: [111500, 1500],
			832: [113500, 1500],
			833: [115200, 1800],
		}
	}
]