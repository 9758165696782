import React from "react";
import { Link } from "react-router-dom";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";

import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  z-index: 1;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;

const Songs = () => {
  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/uebungen`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>
        <h1>LIEDER</h1>
      </Main>
	  </>
  );
};

export default Songs;

