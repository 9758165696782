import React, { useState, useEffect } from "react";
import useSound from 'use-sound';

// assets
import { ReactComponent as Play } from "../assets/icons/Play.svg";
import { ReactComponent as Pause } from "../assets/icons/Pause.svg";
import { ReactComponent as Replay } from "../assets/icons/Replay.svg";

// Data
import { STORY } from "../constants/Story";

import styled from 'styled-components';
const ImageContainer = styled.div`
  aspect-ratio: 4 / 3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 100%;
  max-height: 80vh;
  pointer-events: none;

  div {
    position: absolute;
    top: 4%;
    right: 0%;
    width: 30%;
    height: 30%;
    img {
      width: 100%;
      max-height: 100%;
    }
    &.top-left {
      left: 0%;
      width: 28%;
      height: 28%;
    }
    &.top-right-small {
      width: 25%;
      height: 25%;
    }
  }
`;

const compareNumbers = (a:number, b:number) => {
  return a - b;
}

const useAudio = (chapter:number, story: string) => {
  const [playing, setPlaying] = useState(true);
  const [ended, setEnded] = useState(false);
  const [image, setImage] = useState("");
  const [imagePosition, setImagePosition] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const storypart = STORY.find(stories => stories.name === story);
  const chapters = storypart ? storypart.chapters : STORY[0].chapters;
  const url = chapters[chapter].url;
  const images = chapters[chapter].images;
  const imageSeconds = (images.map(i => i.showAfter)).sort(compareNumbers);
  const [play, { pause, stop, sound }] = useSound(url, {
    onend: function() {
      setPlaying(false);
      setEnded(true);
    }
  });

  useEffect(() => {
    toggle();
      return () => {
        reset();
        stop();
      };
    },
    [play]
  );

  // TODO Autoplay bei Kapitel wechsel

  // TODO Lied austauschen (aktuell im Intro reingeschnitten)

  const toggle = () => setPlaying(!playing);

  const reset = () => {
    stop();
    setImageIndex(0);
    setImage("");
    setImagePosition("");
    setPlaying(true);
    setEnded(false);
  }

  useEffect(() => {
      if (playing) 
      {
        play();
        setTimeout(function() {additionalImageDisplay(imageIndex); }, 500);
      } 
      else 
      {
        pause();
      }
    },
    [playing, ended]
  );

  const additionalImageDisplay = (index:number) => {
    if(sound != null && images.length > index) {
      if (sound.seek() > imageSeconds[index]) {
        setImage(images[index].image);
        setImagePosition(images[index].position);
        index++;
        setImageIndex(index);
      }

      if (sound.playing()) {
        setTimeout(function() { additionalImageDisplay(index); }, 500);
      }
    }
  }

  return [playing, toggle, ended, image, reset, imagePosition] as const;
};

export const AudioButton = (props:any) => {
  const [chapter, setChapter] = useState(props.chapter);
  const story = props.story
  const [playing, toggle, ended, image, reset, imagePosition] = useAudio(chapter, story);

  useEffect(() => {
      if(chapter !== props.chapter)
      {
        setChapter(props.chapter);
      }
    },
    [chapter, props.chapter]
  );

  return (
    <div>
      
      {
        image.length > 0 ?
          <ImageContainer>
            <div className={imagePosition}>
              <img src={require(`../assets/Geschichte/${image}.svg`)} alt={image} />
            </div>
          </ImageContainer>
        :
          <></>
      }

      {
        playing ?
          <Pause onClick={toggle} />
        :
        ended ?
          <Replay onClick={reset} />
        :
          <Play onClick={toggle} /> 
      }
      
    </div>
  );
};
